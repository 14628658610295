<template>
  <div class="col-lg-8 col-md-12">
    <div v-if="content != null" class="blog blog-single-post">
      <div
        class="embed-responsive embed-responsive-16by9"
        v-html="content.video"
      ></div>
    </div>
    <div v-if="content != null" class="d-flex justify-content-around">
      <button class="btn btn-success btn-sm" @click="prevContent">
        <i class="fas fa-chevron-left"></i>
        {{ $t("PartCourseContent.VIDEO_PRECEDENTE") }}
      </button>
      <button class="btn btn-success btn-sm" @click="nextContent">
        {{ $t("PartCourseContent.VIDEO_SUIVANTE")
        }}<i class="fas fa-chevron-right"></i>
      </button>
    </div>

    <br />
    <br />
    <h3 v-if="content == null" class="text-primary">Resume de la partie</h3>

    <div
      class="mt-4 ml-2 description"
      v-if="content != null"
      v-html="content.text"
    ></div>

    <div v-if="content != null" class="container">
      <div v-if="resource != '' && resource != null">
        {{ $t("PartCourseContent.RESSOURCE") }}
        <a :href="content.resource">({{ extractName(content.resource) }})</a>
      </div>
    </div>
    <div v-if="content == null" class="text-center alert alert-danger">
      {{ $t("PartCourseContent.PAS_DE_CONTENNU") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      id: Number,
      title: String,
      video: String,
      text: String,
      resource: String,
    },
  },
  methods: {
    nextContent() {
      this.$emit("finishedPart");
    },
    prevContent() {
      this.$emit("displayPrevContent");
    },
    extractName(file) {
      if (file) {
        const parts = file.split("/");
        return parts[parts.length - 1];
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.decription {
  border: 3px solid gray;
}
</style>
