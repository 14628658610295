<template>
  <div class="col-lg-8 col-md-12 course-skeleton"></div>
</template>
<script>
export default {};
</script>
<style lang="css">
.course-skeleton {
  opacity: 0.7;
  animation: skeleton-loading 0.6s infinite alternate;
  min-height: 250px;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>
