<template>
  <div class="main-wrapper">
    <Navbar />
    <div>
      <div class="container-fluid">
        <!-- Breadcrumb -->
        <div class="breadcrumb-bar">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-md-12 col-12">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">
                      {{ category }}
                    </li>
                  </ol>
                </nav>
                <h2 class="breadcrumb-title">{{ course.title }}</h2>
              </div>
            </div>
          </div>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- Page Content -->
      <div class="content" id="player">
        <div class="container-fluid">
          <div class="row px-5 border-1">
            <div class="tags">
              <div
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                :title="c.title"
                v-for="c in flatedContents"
                :key="c.id"
                :id="'progress-part-' + c.id"
                class="tag progress-part"
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div class="row">
            <PartCourseContent
              v-if="!isLoading"
              @displayPrevContent="displayPrevContent()"
              @finishedPart="nextContent()"
              :content="selectedContent"
            />
            <CourseContentLoader v-else />
            <!-- Blog Sidebar -->
            <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
              <!-- Latest Posts -->
              <div class="card post-widget">
                <div class="card-header">
                  <h4 class="card-title">
                    {{ $t("ShowCourse.TABLE_DE_MATIERES") }}
                  </h4>
                </div>
                <div class="card-body">
                  <div
                    class="accordion"
                    id="accordionExample"
                    v-for="(part, i) in course.courseparts"
                    :key="part.id"
                  >
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0 d-flex justify-content-between">
                          <button
                            class="btn btn-sm btn-link w-100 text-left"
                            type="button"
                            data-toggle="collapse"
                            :data-target="'#collapse' + i"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            {{ i + 1 }} {{ part.title }}
                          </button>
                        </h5>
                      </div>

                      <div
                        :id="'collapse' + i"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <ul v-if="!isLoading" class="ml-3 list-group">
                            <li
                              v-for="(c, j) in part.coursecontents"
                              :key="c.id"
                              class="btn btn-sm btn-link text-left"
                              @click="
                                () =>
                                  changeCourseSection(c, {
                                    contentIndex: j,
                                    partIndex: i,
                                  })
                              "
                            >
                              <router-link
                                :to="{
                                  name: 'ShowCourse',
                                  params: {
                                    idCourse: $route.params['idCourse'],
                                  },
                                  hash: '#player',
                                }"
                              >
                                {{ i + 1 }}.{{ j + 1 }}
                                {{ c.title }}</router-link
                              >
                            </li>
                          </ul>
                          <ul v-else class="ml-3 list-group">
                            <ListItemLoader v-for="i in 10" :key="i" />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="card author-widget clearfix col-lg-8 col-md-12">
              <div class="card-header">
                <h3 class="card-title font-weight-bold">
                  {{ $t("ShowCourseDetails.A_PROPOS_ENSEIGNANT") }}
                </h3>
              </div>
              <div class="card-body">
                <div class="about-author">
                  <div class="about-author-img">
                    <div class="author-img-wrap">
                      <span class="text-primary blog-author-email">
                        <img
                          class="img-fluid rounded-circle"
                          :alt="course.teacher.email"
                          :src="course.teacher.profilePicture.thumbnail"
                        />
                      </span>
                    </div>
                  </div>
                  <div class="author-details">
                    <span class="blog-author-name text-primary">
                      {{ course.teacher.firstName }}
                      {{ course.teacher.lastName }}
                    </span>
                    <p class="mb-0">
                      <small
                        ><em>{{
                          course.teacherProfile.qualification
                        }}</em></small
                      >
                    </p>
                    <p class="text-justify">
                      {{ course.teacherProfile.about }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <DashboardFooter /> -->
</template>

<script>
import Navbar from "@/components/layouts/Navbar";
import PartCourseContent from "@/components/shared/courses/PartCourseContent.vue";
import jwtDecode from "jwt-decode";
import ListItemLoader from "@/components/shared/courses/ListItemLoader.vue";
import CourseContentLoader from "../../components/shared/courses/CourseContentLoader.vue";
// import DashboardFooter from "@/components/layouts/DashboardFooter";

export default {
  components: {
    Navbar,
    CourseContentLoader,
    PartCourseContent,
    ListItemLoader,
  },
  data() {
    return {
      course: {
        id: 1,
        slug: "",
        title: "",
        price: 2333,
        currency: "",
        duration: 445,
        description: "",
        category: 1,
        coursePicture: {
          fullSize: "",
          mediumSquareCrop: "",
          thumbnail: "",
        },
        teacher: {
          id: 0,
          email: "",
          firstName: "",
          lastName: "",
          phone: "",
          city: "",
          country: "",
          profilePicture: {
            fullSize: "",
            mediumSquareCrop: "",
            thumbnail: "",
          },
        },
        teacherProfile: {
          qualification: "",
          about: "",
        },
        courseparts: [],
      },
      flatedContents: [],
      widthBar: 0,
      category: "",
      selectedContent: null,
      classesContentsParts: [],
      selectedPartIndex: 0,
      selectedContentIndex: 0,
      isFisrtLoad: true,
      canAccesss: null,
      isLoading: false,
    };
  },
  methods: {
    changeCourseSection(content, { contentIndex, partIndex }) {
      this.selectedContent = content;
      this.selectedPartIndex = partIndex;
      this.selectedContentIndex = contentIndex;
    },
    async loadCourse() {
      await this.$http
        .get(`/courses/${this.idCourse}/`)
        .then((response) => {
          this.course = response.data;
          this.loadCathegorie();
          this.flatPartContents();
          this.displayNextContent();
          this.loadProgression();
        })
        .catch((err) => {
          if (err.response.status == 404 || err.response.status == 400)
            this.$router.push({
              name: "NotFound",
              params: { pathMatch: "not-found" },
            });
          else this.$toast.error("An error occured!");
          this.isLoading = false;
        });
    },
    async loadCathegorie() {
      await this.$http
        .get(`/courses/category/${this.course.category}/`)
        .then((res) => (this.category = res.data.name));
    },

    async checkCanAccesss() {
      this.isLoading = true;
      await this.$http
        .get(
          `/enrolment/check-user-course-access/${this.course.id}`
        )
        .then((response) => {
          if (response.data === true) {
            this.loadCourse();
          } else {
            this.$router.push({
              name: "InitPayment",
              params: { idCourse: this.$route.params.idCourse },
            });
          }
        });
    },

    /**
     * Pour extraire tous les contentCourse de chaque coursePart dans un meme tableau
     */
    flatPartContents() {
      this.course.courseparts.forEach((coursePart) => {
        this.flatedContents = [
          ...this.flatedContents,
          ...coursePart.coursecontents,
        ];
      });
      this.flatedContents.forEach((c, i) => {
        if (c.id % 2 == 0) this.classesContentsParts[i] = "part-completed";
        else this.classesContentsParts[i] = "part-incompleted";
      });
    },

    /**
     *
     * Pour charger la progression de l'etudiant connecter pour le cours qu'il vient de consulter
     */
    async loadProgression() {
      await this.$http
        .get(
          `/progression/${jwtDecode(localStorage.getItem("access")).user_id}/${
            this.course.id
          }/`
        )
        .then((res) => {
          const stringifiedProgression = JSON.stringify(res.data);
          res.data.forEach((p) => {
            let el = document.getElementById(
              "progress-part-" + p.coursecontent
            );
            el.className += " part-completed";
          });
          localStorage.setItem(
            `course-${this.course.id}`,
            stringifiedProgression
          );
          this.isLoading = false;
        });
    },

    /**
     * Sauvegarde la progression de l'etudiant connecter pour le cours qu'il vient de consulter
     */

    saveProgression() {
      this.$http
        .post("/progression/", {
          student: jwtDecode(localStorage.getItem("access")).user_id,
          coursecontent: this.selectedContent.id,
          course: this.course.id,
        })
        .then(() => {
          this.loadProgression();
        })
        .catch((err) => {
          console.log(err.resonse);
        });
    },
    nextContent() {
      if (this.selectedContent != null) {
        this.saveProgression();
        this.displayNextContent();
      }
    },

    /**
     * Affiche la video suivante du cours
     */

    displayNextContent() {
      if (
        this.isFisrtLoad &&
        this.course.courseparts.length > 0 &&
        this.selectedPartIndex == 0 &&
        this.selectedContentIndex == 0
      ) {
        while (
          this.selectedPartIndex < this.course.courseparts.length &&
          this.course.courseparts[this.selectedPartIndex].coursecontents
            .length <= 0
        )
          this.selectedPartIndex++;
        if (this.selectedPartIndex < this.course.courseparts.length) {
          this.selectedContent =
            this.course.courseparts[this.selectedPartIndex].coursecontents[0];
          this.selectedContentIndex = 0;
        } else {
          this.selectedContent = null;
        }
        this.isFisrtLoad = false;
        return;
      }
      if (this.course.courseparts.length > this.selectedPartIndex) {
        if (
          this.course.courseparts[this.selectedPartIndex].coursecontents
            .length -
            1 >=
          this.selectedContentIndex + 1
        ) {
          this.selectedContent =
            this.course.courseparts[this.selectedPartIndex].coursecontents[
              this.selectedContentIndex + 1
            ];
          this.selectedContentIndex++;
        } else if (
          this.course.courseparts.length >= this.selectedPartIndex + 1 &&
          this.course.courseparts[this.selectedPartIndex + 1].coursecontents
            .length > 0
        ) {
          this.selectedContentIndex = 0;
          this.selectedContent =
            this.course.courseparts[
              this.selectedPartIndex + 1
            ].coursecontents[0];
          this.selectedPartIndex++;
        } else {
          this.$toast.show("Cours termine");
        }
      }
    },

    /**
     * Afficher la video suivante
     */
    displayPrevContent() {
      if (this.selectedContentIndex > 0) {
        this.selectedContent =
          this.course.courseparts[this.selectedPartIndex].coursecontents[
            this.selectedContentIndex - 1
          ];
        this.selectedContentIndex--;
      } else if (this.selectedPartIndex > 0) {
        this.selectedContent =
          this.course.courseparts[this.selectedPartIndex - 1].coursecontents[
            this.course.courseparts[this.selectedPartIndex - 1].coursecontents
              .length - 1
          ];
        this.selectedContentIndex =
          this.course.courseparts[this.selectedPartIndex - 1].coursecontents
            .length - 1;
        this.selectedPartIndex--;
      } else {
        this.$toast.show("Not privious video!");
      }
    },
  },
  props: {
    idCourse: Number,
  },
  beforeMount() {
    this.$nextTick(function () {
      this.checkCanAccesss();
    });
  },
  unmounted() {
    this.$nextTick(function () {
      // TODO enregistrer le niveau de l'utilisateur
    });
  },
};
</script>

<style scoped>
.active a {
  color: #fff;
}
.progress-part {
  display: inline-flex;
  background-color: silver;
  border-bottom: 1px solid rgb(109, 105, 105);
  border-left: 0.5px solid rgb(153, 149, 149);
  margin-left: 1px;
  font-size: 0.1px;
  padding: 10px;
  cursor: pointer;
}

.part-completed {
  background-color: green;
}
</style>
